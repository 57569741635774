import React, { FunctionComponent, Component } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Menu,
	Button,
	DatePicker,
	Input,
	Row,
	Col,
	Card,
	Modal,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepo } from "../repos/ClaimRepo";
import ComponentToPrint from "../components/ComponentToPrint";
// import logo from '../logo.png'
import { imgBaseUrl } from "../api.config";
import ReactToPrint from "react-to-print";
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { bool, boolean } from "@hapi/joi";
import {
	FileThumbnails,
	defaultThumbnailBuilder,
} from "../components/documentThumbnamil";
import { CloudDownloadOutlined } from "@ant-design/icons";
import Moment from "moment";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const { Column } = Table;
const { TextArea } = Input;

class ApprovedClaimReport extends React.Component<any, any> {
	componentRef!: Component<any, {}, any> | Element | null;
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			toData: null,
			fromDate: null,
			id: "",
			selectedClaim: [],
		};
	}

	componentDidMount() {
		this.setState({
			loading: true,
		});
		this.loadClaimDetails();
	}

	loadClaimDetails = async () => {
		var form = new FormData();
		if (this.state.fromDate != null && this.state.toData != null) {
			form.append("FromDate", this.state.fromDate);
			form.append("ToDate", this.state.toData);
		}

		await this.props.getApprovedClaimDetailsReport(form);
		console.log(this.props.ClaimRepo.approverdClaimsForReport);
		this.setState({
			loading: false,
			claims: this.props.ClaimRepo.approverdClaimsForReport,
		});
	};

	onChange = async (date: any, dateString: any) => {
		this.setState({
			toData: Moment(new Date(dateString)).format("YYYY-MM-DD"),
		});
	};

	onChangeFromData = async (date: any, dateString: any) => {
		this.setState({
			fromDate: Moment(new Date(dateString)).format("YYYY-MM-DD"),
		});
	};

	submit = () => {
		if (this.state.fromDate == null || this.state.toData == null) {
			message.error("Please Select the date Range");
			return;
		}
		this.setState({
			loading: true,
		});
		this.loadClaimDetails();
	};

	viewImage = (filename: any) => {
		this.setState({
			viewImage: true,
			filename: filename,
		});
	};

	onClose = () => {
		this.setState({
			visibleView: false,
			viewImage: false,
		});
	};

	handleCancel = () => {
		this.setState({ visible: false, viewImage: false });
	};

	viewClick = (value: any) => {
		this.setState({
			selectedClaim: value,
			visibleView: true,
			viewImage: false,
		});
	};

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<h3>Approved Claim Report</h3>
					<Row>
						<Col span={22}>
							<DatePicker
								style={{ margin: 5 }}
								allowClear={false}
								onChange={this.onChangeFromData}
								placeholder={"From"}
							/>
							<DatePicker
								style={{ margin: 5 }}
								allowClear={false}
								onChange={this.onChange}
								placeholder={"To"}
							/>
							<Button style={{ margin: 5 }} onClick={this.submit} type='primary'>
								Submit
							</Button>
						</Col>
					</Row>
					<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
						{this.state.claims && (
							<Table
								style={{ marginTop: 26, padding: 5 }}
								dataSource={this.state.claims}>
								<Column
									title='Claim ID'
									dataIndex='referenceNo'
									key='referenceNo'
									render={(value, item, index) => (value == "" ? "N/A" : value)}
								/>
								<Column
									width='100'
									title='Epf No'
									dataIndex='user'
									key='user'
									render={(value, item, index) => value.userDetails.employeeNumber}
								/>
								<Column
									width='100'
									title='Employee Name'
									dataIndex='user'
									key='user'
									render={(value, item, index) =>
										value.userDetails.firstName + " " + value.userDetails.lastName
									}
								/>
								<Column
									width='100'
									title='Created Date'
									dataIndex='createOn'
									render={(value, item, index) => value.split("T")[0]}
								/>
								<Column
									title='Action'
									key='index'
									render={(value, item, index) => (
										<div>
											<span>
												<a>
													<Tag onClick={() => this.viewClick(value)} color='#875ec4'>
														{" "}
														View
													</Tag>
												</a>
											</span>
										</div>
									)}
								/>
							</Table>
						)}
					</div>
				</Spin>
				<Drawer
					width={460}
					closable={false}
					onClose={this.onClose}
					visible={this.state.visibleView}>
					<h3>Related Documents</h3>
					<Row>
						{/* { this.state.selectedClaim.length != 0 && this.state.selectedClaim.relatedDocumeents.map((doc: any) =>
                                        //   <Avatar style={{margin : 10  }} size={160} shape="square" src={imgBaseUrl+doc.fileName }/>
                                           <a  onClick={() => this.viewImage(doc.name)} >
                                           <Card
                                           hoverable
                                           style={{ width: 140 , height : 200 , margin : 4 }}
                                           cover={<img alt="example" src={imgBaseUrl+doc.name} />}
                                         >
                                           
                                         </Card>
                                         </a>
                                        )
                         
                      }   */}
						{this.state.selectedClaim.length !== 0 && (
							<FileThumbnails
								thumbnailBuilder={(file) =>
									defaultThumbnailBuilder({
										file,
										overlay: (
											<Menu>
												<Menu.Item
													icon={<CloudDownloadOutlined />}
													onClick={() => window.open(file.src)}>
													Download
												</Menu.Item>
											</Menu>
										),
									})
								}
								files={this.state.selectedClaim.relatedDocumeents.map(
									(doc: any, i: any) => ({
										name: doc.name,
										src: `${imgBaseUrl}${doc.name}`,
										meta: { index: i, ...doc },
									})
								)}
							/>
						)}
					</Row>
				</Drawer>
				<Modal
					bodyStyle={{ backgroundColor: "transparent" }}
					width={650}
					mask={false}
					onCancel={this.handleCancel}
					visible={this.state.viewImage}
					footer={[]}>
					<div style={{ margin: "auto" }}>
						<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
							Download Image
						</Button>
						<TransformWrapper>
							<TransformComponent>
								<img
									style={{ minWidth: 600, maxWidth: 600 }}
									src={imgBaseUrl + this.state.filename}
									alt='test'
								/>
							</TransformComponent>
						</TransformWrapper>
					</div>
				</Modal>
			</div>
		);
	}
}

const WrappedApprovedClaimReport = ApprovedClaimReport;
const withClaimRepo = connect(
	(state: any) => state,
	ClaimRepo
)(WrappedApprovedClaimReport);
export default withClaimRepo;
