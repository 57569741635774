import React, { FunctionComponent } from "react";
import {
	Empty,
	Divider,
	Button,
	Typography,
	message,
	Row,
	Input,
	Avatar,
	Col,
	Badge,
	Radio,
	Modal,
	Spin,
	Form,
	DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	UploaderState,
	FileDataMapper,
	MultipleFileUploader,
	defaultImageExtensions,
} from "../components";
import { withRouter } from "react-router";
import { lionUserManager } from "../repos/UserRepo";
import { UserRepoActions } from "../repos/UserRepo";
import { BorderOuterOutlined, FileTextOutlined } from "@ant-design/icons";
import { Elements } from "../components/Dashboard";

import { DashboardActions } from "../repos/DashboardRepo";
import "./style.css";
const moment = require("moment");
const { Paragraph, Text } = Typography;
const smallCardMediaSpans = {
	xl: 7,
	xs: 24,
	sm: 24,
	lg: 12,
};

const CardMediaSpans = {
	xl: 6,
	xs: 24,
	sm: 24,
	lg: 12,
};

const getColor = (i: number) => {
	switch (i) {
		case 0:
			return "#4C56BA";
		case 1:
			return "#29275F";
		case 2:
			return "#F64B53";
		case 3:
			return "#929CB7";
		case 4:
			return "#48c5d9";
		case 5:
			return "#0d52d0";
		case 6:
			return "#48c5d9";
		case 7:
			return "#9c2e33";
		default:
			return "#0B3C49";
	}
};
var Amount: any = 0;
const data = [
	{
		description: "Ant Design, a design language for background   ",
		status: 2,
		statusValue: "APPROVED",
	},
];

const layout = {
	labelCol: {
		span: 6,
	},
	wrapperCol: {
		span: 18,
	},
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

const state_value = ["DRAFT", "PENDING", "APPROVED", "REJECTED", "DISABLED"];
const color = [
	"#0d52d0",
	"#faad14",
	"#52c41a",
	"#d9363e",
	"#0d52d0",
	"#48c5d9",
	"#616eff",
	"#ff4d4f",
];

class Dashboard extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			isVisible: false,
			date: moment().format("YYYY-MM-DD"),
			invoice_date: moment().format("YYYY-MM-DD"),
			amount: "",
			description: "",
			invoiceNo: "",
			summaryData: [],
			claimHistory: [],
			fileMapperData: {
				files: [],
				data: [],
			},
			loading: true,
			value: -1,
			imageIsEmpty: false,
			quata: 0,
			totalEntitlement: 0,
			totalRemaining: 0,
		};
	}

	componentDidMount() {
		this._getData("-1");
	}

	async _getData(status: any) {
		this.setState({
			loading: true,
		});
		await this.props.getUserHistory(status, "9");
		await this.props.getSummaryData(0);
		await this.props.getQuataBalance();

		var totalAmount = this.props.DashboardRepo.summaryData.filter(
			(data: any) => data.title == "Total Amount"
		)[0].value;
		var availableAmount = this.props.DashboardRepo.summaryData.filter(
			(data: any) => data.title == "Remaining Balance"
		)[0].value;

		this.setState({
			summaryData: this.props.DashboardRepo.summaryData,
			totalEntitlement: totalAmount,
			totalRemaining: availableAmount,
			claimHistory: this.props.DashboardRepo.userHistory,
			loading: false,
			imageIsEmpty: false,
			quata: this.props.DashboardRepo.quata,
		});
	}

	createClaim = () => {
		this.setState({
			isVisible: true,
		});
	};

	onCancelModal = () => {
		this.setState({
			isVisible: false,
			date: moment().format("YYYY-MM-DD"),
			invoice_date: moment().format("YYYY-MM-DD"),
		});
	};

	onFinish = async (values: any) => {
		if (this.chechExistClaims()) {
			message.error("This claim is already exist");
			return;
		} else {
		}

		if (this.state.amount == "") {
			message.error("Please enter the amount");
			return;
		}
		if (this.state.invoice_date == "") {
			return false;
		}
		if (this.state.invoiceNo == "") {
			message.error("Please enter the Invoice Number");
			return;
		}
		if (this.state.description == "") {
			message.error("Please enter the Description");
			return;
		}
		if (this.state.fileMapperData.data.length == 0) {
			this.setState({ imageIsEmpty: true });
			return;
		}

		this.setState({ loading: true, isVisible: false });

		const form = new FormData();
		var claimDetails = {
			amount: this.state.amount,
			userId: lionUserManager.user.data.USER_ID,
			billDate: this.state.invoice_date,
			description: this.state.description,
			invoiceNo: this.state.invoiceNo,
		};

		this.state.fileMapperData.files.forEach((element: any) => {
			form.append("images", element);
		});

		form.append("reimbursement", JSON.stringify(claimDetails));
		form.append("status", "0");

		var response = await this.props.createClaim(form);

		if (response) {
			this._getData("-1");
			this.setState({
				loading: false,
				amount: "",
				description: "",
				invoiceNo: "",
				date: moment().format("YYYY-MM-DD"),
				invoice_date: moment().format("YYYY-MM-DD"),
			});
		} else {
			message.error("error");
			this.setState({ loading: false });
		}
	};

	chechExistClaims() {
		var existingClaim = this.props.DashboardRepo.reimbursements.find(
			(claim: { invoiceNo: any; invoiceDate: any; billDate: any }) =>
				claim.billDate === this.state.invoice_date + "T05:30:00.000+0530" &&
				claim.invoiceNo === this.state.invoiceNo
		);

		if (existingClaim != undefined) {
			return true;
		}
		return false;
	}

	onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	disabledDate(current: any) {
		return current && current < moment().subtract(3, "months");
	}

	onChangeAmount = (e: any) => {
		Amount = e.target.value.replace(",", "");

		var remainQuata = this.state.quata;

		var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
		var checkValue = Amount.match(regex);

		if (checkValue == null && Amount != "") {
			message.error("Enter Valid Amount. For example: Rs. 523.36");
			this.setState({
				anountStatus: "success",
				amount: "",
			});
			return;
		}

		if (Amount <= 0 || Amount == "") {
			message.error("Please Enter Valid Amount");
		}

		if (remainQuata < Number(Amount)) {
			message.error("No quota available");
			this.setState({
				amount: "",
			});
			return;
		}
		this.setState({
			amount: Amount,
		});
	};

	changeInvoiceDate = (date: any, dateString: any) => {
		var currnetDate = new Date(this.state.date).getTime(); // gives 1486492200000
		var invoiceDate = new Date(dateString).getTime();

		if (currnetDate < invoiceDate) {
			message.error("Please Add The Correct Date");
			this.setState({
				invoice_date: moment().format("YYYY-MM-DD"),
			});
			return;
			// your code goes here
		}

		this.setState({
			invoice_date: dateString,
		});
	};

	onFilterChange = (e: any) => {
		this.setState({
			value: e.target.value,
			loading: true,
		});

		this._getData(e.target.value);
	};

	onChangeDescription = (e: any) => {
		this.setState({
			description: e.target.value,
		});
	};

	onChangeInvoiceNo = (e: any) => {
		this.setState({
			invoiceNo: e.target.value,
		});
	};

	onMultipleUploaderSubmit = (data: UploaderState) => {
		this.setState({
			imageIsEmpty: false,
			fileMapperData: data,
		});
	};

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<Row gutter={[10, 10]}>
						{this.state.totalEntitlement != "10,000,000.00"
							? this.state.summaryData &&
							  this.state.summaryData.length > 0 &&
							  this.state.summaryData.map((key: any, i: number) => (
									<Col {...CardMediaSpans}>
										<Elements.ValueCard
											header={key.title}
											backgroundColor={getColor(i)}
											icon={<FileTextOutlined />}>
											{key.value}
										</Elements.ValueCard>
									</Col>
							  ))
							: this.state.summaryData &&
							  this.state.summaryData.length > 0 &&
							  this.state.summaryData.map((key: any, i: number) => (
									<Col {...CardMediaSpans}>
										{key.title == "Remaining Balance" || key.title == "Total Amount" ? (
											<Elements.ValueCard
												header={key.title}
												backgroundColor={getColor(i)}
												icon={<FileTextOutlined />}>
												Unlimited
											</Elements.ValueCard>
										) : (
											<Elements.ValueCard
												header={key.title}
												backgroundColor={getColor(i)}
												icon={<FileTextOutlined />}>
												{key.value}
											</Elements.ValueCard>
										)}
									</Col>
							  ))}
					</Row>
					<Row
						style={{
							height: 12,
							backgroundColor: "#f0f0f0",
							marginLeft: -25,
							marginTop: 20,
							marginRight: -25,
						}}></Row>
					<Row>
						<Col style={{ padding: 10, marginTop: 4 }} span={17}>
							<Row>
								<Col span='3'>
									<Text style={{ fontSize: 18 }} strong>
										My Claims{" "}
									</Text>
								</Col>
								<Col span='19'>
									<Radio.Group
										style={{ marginLeft: 60 }}
										onChange={this.onFilterChange}
										value={this.state.value}>
										<Radio value={0}> DRAFT</Radio>
										<Radio value={1}>PENDING</Radio>
										<Radio value={2}>APPROVED</Radio>
										<Radio value={3}>REJECTED</Radio>
										<Radio value={-1}>All</Radio>
									</Radio.Group>
								</Col>
								<Col span='2' style={{ display: "flex", justifyContent: "flex-end" }}>
									<Link to={`view-all`}>
										<p>View All</p>
									</Link>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								{this.state.claimHistory &&
									this.state.claimHistory.length > 0 &&
									this.state.claimHistory.map((key: any, i: number) => (
										<Col
											{...smallCardMediaSpans}
											className='row-layout-claim-detail'
											style={{ margin: 6, padding: 15 }}>
											<Link to={`view-claim/${key.id}`}>
												<Col
													span='24'
													style={{ display: "flex", justifyContent: "flex-end" }}>
													<Badge
														count={state_value[key.reimbursementAction.status]}
														style={{ backgroundColor: color[key.reimbursementAction.status] }}
													/>
												</Col>
												<Row>
													<Col span={4} style={{}}>
														<Avatar
															style={{
																backgroundColor: "#4c56ba",
																verticalAlign: "middle",
															}}
															size={25}>
															CM
														</Avatar>
													</Col>
													<Text style={{ fontSize: 15, marginTop: 7, marginLeft: 5 }}>
														{" "}
														{key.referenceNo}{" "}
													</Text>
												</Row>
												<Col style={{ paddingLeft: 10, paddingTop: 5 }}>
													<Text style={{ fontSize: 12 }}> {key.note} </Text>
												</Col>
												<Col style={{ paddingLeft: 10, paddingTop: 10 }}>
													<Text strong style={{ fontSize: 12 }}>
														{" "}
														Amount : Rs.{" "}
														{(Math.round(key.amount * 100) / 100)
															.toFixed(2)
															.toString()
															.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
													</Text>
												</Col>
												<Col style={{ paddingLeft: 10, paddingTop: 5 }}>
													<Text style={{ fontSize: 12, color: "#bbbbbb" }}>
														{" "}
														Bill Date : {key.billDate.split("T")[0]}{" "}
													</Text>
												</Col>
												<Col style={{ paddingLeft: 10, paddingTop: 5 }}>
													<Text style={{ fontSize: 12, color: "#bbbbbb" }}>
														{" "}
														Created Date : {key.createOn.split("T")[0]}
													</Text>
												</Col>
											</Link>
										</Col>
									))}
							</Row>
						</Col>
						<Divider type='vertical' style={{ width: 14, height: 600 }} />
						<Col span={6} style={{ padding: 10 }}>
							<h3
								onClick={() => this.createClaim()}
								className='box-button'
								style={{
									color: "#fff",
									width: "110%",
									height: "60px",
									borderRadius: "20px",
								}}>
								{" "}
								Make Your Claim
							</h3>
							{/* <Button  style={{ width: "110%", height: "12%" }} type="primary" ghost>
                        + Add / Claim
                    </Button> */}

							<div style={{ marginTop: 60 }}>
								{" "}
								<Empty />
							</div>
						</Col>
					</Row>

					<Modal
						title={"Create Claim"}
						style={{ top: 20 }}
						visible={this.state.isVisible}
						onCancel={() => this.onCancelModal()}
						footer={null}
						destroyOnClose={true}>
						<Form
							{...layout}
							initialValues={{
								remember: false,
							}}
							onFinish={this.onFinish}
							onFinishFailed={this.onFinishFailed}>
							<Form.Item label='* Amount'>
								<Input onChange={this.onChangeAmount} value={this.state.amount} />
								{/* <p style={{fontSize:10}}>sasasa</p> */}
							</Form.Item>

							{this.state.totalEntitlement != "10,000,000.00" && (
								<Form.Item label='Entitlement' name='entitlement'>
									{/* <Input  placeholder={"Rs. " + (Math.round(this.state.totalEntitlement * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  disabled /> */}
									<Input placeholder={"Rs. " + this.state.totalEntitlement} disabled />
								</Form.Item>
							)}
							{this.state.totalEntitlement != "10,000,000.00" && (
								<Form.Item label='Remaining ' name='remainingAmount'>
									<Input placeholder={"Rs. " + this.state.totalRemaining} disabled />
								</Form.Item>
							)}

							<Form.Item
								label='Invoice Date'
								rules={[
									{
										required: true,
										message: "Please input your Invoice Date!",
									},
								]}>
								<DatePicker
									onChange={this.changeInvoiceDate}
									disabledDate={this.disabledDate}
									allowClear={false}
									value={moment(this.state.invoice_date, "YYYY-MM-DD")}
								/>
							</Form.Item>
							<Form.Item
								label='* Invoice Number '
								// name="description"
								// rules={[
								// {
								//     required: true,
								//     message: 'Please input your description!',
								// },
								// ]}
							>
								<Input maxLength={200} onChange={this.onChangeInvoiceNo} />
							</Form.Item>
							<Form.Item
								label='* Description '
								// name="description"
								// rules={[
								// {
								//     required: true,
								//     message: 'Please input your description!',
								// },
								// ]}
							>
								<Input maxLength={200} onChange={this.onChangeDescription} />
							</Form.Item>

							<MultipleFileUploader
								accept={[
									".png",
									".jpeg",
									".jpg",
									".PNG",
									".JPG",
									".JPEG",
									".pdf",
									".PDF",
								]}
								onChange={this.onMultipleUploaderSubmit}
							/>
							{this.state.imageIsEmpty && (
								<p style={{ color: "#ff4d4f" }}>* please upload the relevant images </p>
							)}
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Modal>
				</Spin>
			</div>
		);
	}
}

const WrappedDashboardView = Dashboard;
const withDashboardRepo = connect(
	(state: any) => state,
	DashboardActions
)(WrappedDashboardView);

export default withDashboardRepo;

// const WrappedDashboardView = Dashboard;
// const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);

// export default withDashboardRepo;
