import React, { Component } from "react";
import { Menu, Row, Col, Popover, Button, Switch as Toggler, Avatar, Input } from 'antd';
import { ShowTheLocationWithRouter, ContentPresenter, DefaultAvatar, SimpleHeader } from './components'
import { Dashboard , Test, ViewAllClaim , ViewClaim, ApproveClaims , SapPending , UploadDocuments , ApprovedClaimReport} from './pages'
import { logOutAsLionUser, lionUserManager, userManager } from './repos/UserRepo'
import DefaultLayout from './layouts/DefaultLayout';
import {
    withRouter,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import './App.css';
import { RouteConfig } from "./route.config";
import {
    AppstoreOutlined,
    UnorderedListOutlined,
    AuditOutlined
} from '@ant-design/icons';
import './ProtectedApp.css';
import logo from '../images/logo.png'
import { LionUser } from "user-manager";

class ProtectedApp extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { collapsed: true, };
    }

    toggle() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <DefaultLayout
                header={<ProtectedAppHeader />}
                sidebar={<ProtectedAppSidebar collapsed={this.state.collapsed} toggle={this.toggle.bind(this)} />}
                isSidebarCollapsed={this.state.collapsed}>
                {/* <FilterHeaderStrip /> */}
                <div className="breadcrumbs-container">
                    <ShowTheLocationWithRouter />
                </div>
                <Switch>
                    <Route exact path={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
                        <ContentPresenter><div><Dashboard /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_HOME}>
                        <ContentPresenter><div><Test /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_VIEW_ALL}>
                        <ContentPresenter><div><ViewAllClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_CLAIM}>
                        <ContentPresenter><div><ViewClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_APPROVE_CLAIMS}>
                        <ContentPresenter><div><ApproveClaims /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_PENDING_SAP}>
                        <ContentPresenter><div><SapPending /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_DOCUMENTS}>
                        <ContentPresenter><div><UploadDocuments /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_APPROVED_CLAIMS}>
                        <ContentPresenter><div><ApprovedClaimReport /></div></ContentPresenter>
                    </Route>
                    <Route path={RouteConfig.PROTECTED_ROOT}>
                        <Redirect to={{ pathname: RouteConfig.PROTECTED_MAIN_DASHBOARD }} />
                    </Route>

					
                </Switch>
            </DefaultLayout>
        );
    }
}

class FilterHeaderStrip extends Component<any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Col className="header-strip-container">
                <Row>
                    <Input />
                </Row>
            </Col>
        )
    }
}

class ProtectedAppHeader extends Component<any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { }

    render() {
        return (
            <Row justify="end" align="middle" className="main-header-container" gutter={35}>
                <Col className="main-header-notification-icon">
                    {/* <Popover content={data && <NotificationContainer data={data} />} placement="topLeft" trigger="click">
                        <Badge count={data && data.length}>
                            <div className="header-icon"><BellFilled /></div>
                        </Badge>
                    </Popover> */}
                </Col>
                <Col>
                    <Popover content={userManager.user && <UserPopover user={userManager.user} />} placement="topLeft" trigger="click">
                        <div><DefaultAvatar user={lionUserManager.user} /></div>
                    </Popover>
                </Col>
            </Row>

        );
    }
}

export class UserPopover extends Component<{ user: LionUser }>{
    render() {
        return (
            <Row className="user-popover-container" gutter={[10, 10]} align="middle" justify="center">
                {lionUserManager && <Col>
                    <Row align="middle" justify="center" className="user-popover-avatar-name">
                        <Avatar src={lionUserManager.user.data.User.imagePaths} size={70} />
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.firstName + " " + lionUserManager.user.data.User.lastName}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.designationName}
                    </Row>
                    <Row style={{ marginTop: 1 }} align="middle" justify="center" className="user-popover-avatar-email" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.email}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center">
                        <Button onClick={logOutAsLionUser} shape="round" className="user-popover-user-sign-out-button">Sign Out</Button>
                    </Row>
                </Col>}
            </Row>
        );
    }
}

class NotificationContainer extends Component<any>{
    render() {
        return (
            <Row style={{ width: 400 }}>
                <Col span={24}>
                    <SimpleHeader title="Notifications" />
                </Col>
                <Col className="notification-container">{

                }</Col>
            </Row>
        );
    }
}

class ProtectedAppSidebar extends Component<{ collapsed: boolean, toggle: () => void } | any> {
    constructor(props: any) {
        super(props);
	}
	
	componentDidMount(){
		console.log(lionUserManager)
	}

    render() {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="flex-center logo"  >
                        <img src={logo} style={{ width: '100%' }}></img>
                    </div>

                    <Menu mode="inline" defaultSelectedKeys={['0']}>
                        <Menu.Item key="0">
                            <Link to={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
                                <AppstoreOutlined />
                                <span>Dashboard</span>
                            </Link>
                        </Menu.Item>
						{lionUserManager.user.data.AUTHORITIES.includes("ApproveClubMembershipClaim")  &&  <Menu.Item key="1">
                            <Link to={RouteConfig.PROTECTED_APPROVE_CLAIMS}>
                                <AuditOutlined />
                                <span>Approve Claims</span>
                            </Link>
                        </Menu.Item>}
						{lionUserManager.user.data.AUTHORITIES.includes("ApproveClubMembershipClaim")  &&
                        <Menu.Item key="3">
                            <Link to={RouteConfig.PROTECTED_PENDING_SAP}>
                            <UnorderedListOutlined />
                                <span>Pending Payment</span>
                            </Link>
						</Menu.Item> 
						}
						{lionUserManager.user.data.AUTHORITIES.includes("ApproveClubMembershipClaim")  &&
                        <Menu.Item key="4">
                            <Link to={RouteConfig.PROTECTED_APPROVED_CLAIMS}>
                            <UnorderedListOutlined />
                                <span>Approved Claims</span>
                            </Link>
						</Menu.Item> 
						}
                        <Menu.Item key="5">
                            <Link to={RouteConfig.PROTECTED_DOCUMENTS}>
                                <AppstoreOutlined />
                                <span>Documents</span>
                            </Link>
                        </Menu.Item>
 
                    </Menu>
                </div>

                <span className="sidebar-toggle-button">
                    <Toggler size="small" defaultChecked={this.props.collapsed} onChange={this.props.toggle} />
                </span>
            </React.Fragment>
        )
    }
}

// const ProtectedAppHeaderWithUser = connect((state => state), UserActionTypes)(ProtectedAppHeader);

export default withRouter(ProtectedApp);