
import React, { Component } from 'react';
import { LoginPage } from './pages'
import { Provider } from 'react-redux';
import { getStore } from './repos';
import { lionUserManager } from './repos/UserRepo'
import { LionUser, authStream, AuthDetails, AuthDetailSubscription, AuthSubscriberActions } from 'user-manager';
import { ActionIndicator } from './components/Indicator';
import ProtectedApp from './ProtectedApp';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import { RouterProps, RouteComponentProps } from 'react-router';
import { RouteConfig } from './route.config';
import { Row, Col } from 'antd';

export default class App extends React.Component<any>{
  constructor(props: React.Props<any>) {
    super(props);
  }

  render() {
    return (
      <Provider store={getStore()}>
        <Router>
          <Switch>
            <Route path={RouteConfig.PROTECTED} render={(props: RouteComponentProps) =>
              <ProtectedRoute
                component={<ProtectedApp />}
                redirectLink={RouteConfig.LOGIN}
                {...props}
              />}
            />
            <Route exact path={RouteConfig.LOGIN} component={LoginPage}/>
            <Route exact path={RouteConfig.root} component={RedirectToLogin} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

const RedirectToLogin: React.FC = () => <Redirect to={{pathname: RouteConfig.LOGIN}}/>

interface ProtectedRouteProps extends RouterProps {
  redirectLink: string,
  component: React.ComponentType<any> | React.ReactElement
  rest?: any
}

export class ProtectedRoute extends Component<ProtectedRouteProps, any> {
  subscription: AuthDetailSubscription
  constructor(props: any) {
    super(props);
    this.subscription = authStream.subscribe(this.onAuthDetails.bind(this));
    this.state = { is: null };
    this.setIs = this.setIs.bind(this);
    this.setIs();
  }

  onAuthDetails(details: AuthDetails) {
    this.setState({ is: details.isAuthentic })
  }
  
  async setIs() {
    const is: boolean = await lionUserManager.authenticationManager.checkPermission();
    const user: LionUser = lionUserManager.localUserManager.getData();

    if (is) {
      this.setState({ is })
    } else if (!is && !lionUserManager.authenticationManager.authDetails && user) {
      lionUserManager.authenticationManager.refreshAuth(new AuthDetails({isAuthentic: false, user, action: AuthSubscriberActions.REFRESH_TOKEN}));
    } else{
      this.setState({ is: false })
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
    this.setState({ is: null })
  }

  render() {
    if (this.state.is != null)
      if (this.state.is)
        return this.props.component
      else return <Redirect to={{ pathname: this.props.redirectLink }} />
    else return <Row align="middle" justify="center" style={{marginTop: 30}}><ActionIndicator /></Row>
    // return this.props.component
  }
}

// http://localhost:3000/protected/artwork-create