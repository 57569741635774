import React, { Component } from 'react';
// import eimsky from '/logo192.png'
export class PoweredByFooter extends Component {
    render() {
        return (
            <div className="absolute powered-by-footer-container bottom flex-center">
                {/* <img src={eimsky} className="powered-by-footer"/> */}
            </div>
        )
    }
} 