import React, { FunctionComponent } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Menu,
	Drawer,
	Typography,
	Input,
	Descriptions,
	Row,
	Avatar,
	Modal,
	Button,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepo } from "../repos/ClaimRepo";
import { lionUserManager } from "../repos/UserRepo";
// import logo from '../logo.png'
import "./style.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
	FileThumbnails,
	defaultThumbnailBuilder,
} from "../components/documentThumbnamil";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { imgBaseUrl } from "../api.config";
const { Paragraph, Text } = Typography;
const { Column } = Table;
const { TextArea } = Input;
 
class ApproveClaim extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
              claims : [],
              loading : false,
              visibleView: false,
              selectedClaim : {},
              viewImage : false,
              filename : "",
              visibleRejectModal : false,
              reason : ""
         };
    }

    componentDidMount() {
           this._getData()
    }

    async  _getData() {
        this.setState({loading : true})
        await this.props.getAllClaims();
 

        var claimList: any[] = []; 
        this.props.ClaimRepo.allClaims.reverse().forEach((element: any) => {
             var data = {
                 "id" : element.id,
                 "description" : element.description,
                 "billDate" : element.billDate.split("T")[0],
                 "employee_id" : element.user.id,
                 "employeeNumber" : element.user.employeeNumber,
                 "firstName" : element.user.firstName,
                 "lastName" : element.user.lastName,
                 "designation" : element.user.designationName,
                 "amount" : element.amount,
                 "reimbursementAction" : element.reimbursementAction,
				 "relatedDocumeents" : element.relatedDocumeents,
				 "referenceNo" : element.referenceNo

             }
             claimList.push(data);
        });

        

        this.setState({claims : claimList , loading : false})
       
        
    }
 

    viewDetails(id : any){

        var result = this.state.claims.find((o: { id: string; }) => o.id === id) ; 
 
        this.setState({
            visibleView: true,
            selectedClaim : result
          });
    }

    viewImage(filename : any){
       this.setState({
        filename : filename,
        viewImage : true
       })
         
    }

    handleCancel =() => {
        this.setState({
            viewImage : false,
            visibleRejectModal : false
           })
    }


    onClose = () => {
        this.setState({
          visibleView: false,
        });
      };

      async updateStatus(id : any , status : any , index : any ){ 

        const form = new FormData();
        form.append("id", id);
        form.append("approve", status);
        form.append("userId", lionUserManager.user.data.USER_ID);
        form.append("comment", this.state.reason);


 
        this.setState({loading : true})
        var res =  await this.props.updateClaimStatus(form );
        const claims = [...this.state.claims];
       // claims.splice(index, 1);  
       if(res){
        this._getData()
        this.setState({
             reason: "",
            loading : false
        });  
            message.success("Successfully Updated");
       }
       else{
        this.setState({loading : false})
        message.error("Something Went Wrong");
       }


      }

      rejectClaim(id : any , index : any){
        this.setState({visibleRejectModal : true , id : id , index :index});
    }

    rejectedReasonTextChange = (reason: any) => {
        this.setState({
            reason: reason.target.value,
        });

    }

    submitReason =() => {
        if(this.state.reason == ""){
            message.error("Please Enter the Reason");
            return;

        }
        (async () => {
            await this.updateStatus(this.state.id, 0 , this.state.index);
        })();
        
        this.setState({visibleRejectModal : false})

    };



    render() {


        return (
            <div >
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}>   
                 <div style={{opacity : this.state.loading ? 0.8 : 1}}>
                <h3>Pending Claims</h3>
               
                <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claims.reverse()}> 
				    <Column  title="Claim ID" dataIndex="referenceNo" key="referenceNo" />
					 <Column width="100" title="Employee Name" key="Employee Name"  render={(value, item, index) => (  value.firstName + " " + value.lastName )}/>
                    <Column width="100" title="Designation" dataIndex="designation" key="designation" />
                   <Column width="100" title="Invoice Date" dataIndex="billDate" key="billDate" />
                   
                    <Column title="Amount" dataIndex="amount" key="amount" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/> 
                    {/* <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <div>
                                      <Link  to={`pending-claim-details/${value.id}`}> 
                                            <span>
                                            <a ><Tag   color="#875ec4"> View</Tag></a>
                                            </span>
                                       </Link>
                                </div>
                            )}
                            /> */}
							<Column
								title='Action'
								key='index'
								render={(value, item, index) =>
									value.reimbursementAction.status == 1 ? (
										<div>
											<span>
												<a onClick={() => this.viewDetails(value.id)}>
													<Tag color='#ababab'> View</Tag>
												</a>
											</span>
											<span>
												<a onClick={() => this.updateStatus(value.id, "1", index)}>
													<Tag color='#52c41a'> Approve</Tag>
												</a>
											</span>

											<span>
												<a onClick={() => this.rejectClaim(value.id, index)}>
													<Tag color='#cd201f'> Reject</Tag>
												</a>
											</span>
										</div>
									) : (
										<p>DONE</p>
									)
								}
							/>
						</Table>
						<Drawer
							width={460}
							closable={false}
							onClose={this.onClose}
							visible={this.state.visibleView}>
							<div className='desc'>
								<h3>Claim Details</h3>
								<Paragraph>
									{this.state.selectedClaim != null && (
										<Descriptions>
											<Descriptions.Item span={24}>
												{" "}
												Description : {this.state.selectedClaim.description}
											</Descriptions.Item>
										</Descriptions>
									)}
								</Paragraph>
							</div>

							<h3>Related Documents</h3>
							<Row>
								{/* { this.state.selectedClaim.relatedDocumeents && this.state.selectedClaim.relatedDocumeents.map((doc: any) =>
                                        //   <Avatar style={{margin : 10  }} size={160} shape="square" src={imgBaseUrl+doc.fileName }/>
                                        <a onClick={() => this.viewImage(doc.name)}>
                                         <Avatar src={imgBaseUrl + doc.name } size={150} style={{ margin: 2 }} />
                                      </a>
                                        )
                         
                      }   */}
								{this.state.selectedClaim.relatedDocumeents && (
									<FileThumbnails
										thumbnailBuilder={(file) =>
											defaultThumbnailBuilder({
												file,
												overlay: (
													<Menu>
														<Menu.Item
															icon={<CloudDownloadOutlined />}
															onClick={() => window.open(file.src)}>
															Download
														</Menu.Item>
													</Menu>
												),
											})
										}
										files={this.state.selectedClaim.relatedDocumeents.map(
											(doc: any, i: any) => ({
												name: doc.name,
												src: `${imgBaseUrl}${doc.name}`,
												meta: { index: i, ...doc },
											})
										)}
									/>
								)}
							</Row>
						</Drawer>

						<Modal
							bodyStyle={{ backgroundColor: "transparent" }}
							width={650}
							mask={false}
							onCancel={this.handleCancel}
							visible={this.state.viewImage}
							footer={[]}>
							<div style={{ margin: "auto" }}>
								<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
									Download Image
								</Button>
								<TransformWrapper>
									<TransformComponent>
										<img
											style={{ minWidth: 600, maxWidth: 600 }}
											src={imgBaseUrl + this.state.filename}
											alt='test'
										/>
									</TransformComponent>
								</TransformWrapper>
							</div>
						</Modal>

						<Modal
							title='Please Enter The Rejected Reason'
							visible={this.state.visibleRejectModal}
							onOk={this.submitReason}
							onCancel={this.handleCancel}
							footer={[
								<Button key='submit' type='primary' onClick={this.submitReason}>
									Submit
								</Button>,
							]}>
							<TextArea
								rows={4}
								onChange={this.rejectedReasonTextChange}
								value={this.state.reason}
							/>
						</Modal>
					</div>
				</Spin>
			</div>
		);
	}
}

const WrappedApproveClaim = ApproveClaim;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepo
)(WrappedApproveClaim);
export default withDashboardRepo;
