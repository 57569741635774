import React, { Component } from 'react';
import {  message , Modal ,  Form, Input, Button, Select} from 'antd';
 

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
 
 
class Button1 extends React.Component<any, any> {
     constructor(props : any) {
      super(props);
   
      this.state = {
        isShow: true,
        modal1Visible : false,
        username: '',
        password: '',
      };
    }
    

    setModal1Visible(modal1Visible : any) {
        this.setState({ modal1Visible });
      }
     
      onFinish = (values:any) => {
        console.log(values);
      };

      handleSubmit = () => {
        this.props.parentCallback(this.state);
   }
    

      onChangeDescription =(e : any)=>{
         message.success(e.target.value)
    }
    handleChange = (e : any) => this.setState({username : e.target.value });

    componentDidUpdate() {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    }
    
      
   
    render() {
      return (
          <div>
           <div>
                <div>Username:</div>
                <input name="username" onChange={this.handleChange} />
                </div>
            <br />
            <div>
                <div>Password:</div>
                <input name="password" onChange={this.handleChange} />
                </div>
            <br />
                <div>
                  <button onClick={this.handleSubmit}>Submit</button>
                </div>
        </div>
 
      );
    }
  }
 

export default Button1;
 