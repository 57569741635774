import React, { FunctionComponent, Component } from "react";
import {
	Spin,
	Card,
	Typography,
	Row,
	Button,
	Descriptions,
	Menu,
	Avatar,
	Col,
	Form,
	Modal,
	message,
	Input,
	DatePicker,
	Badge,
	Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ClaimRepo } from "../repos/ClaimRepo";
import { lionUserManager } from "../repos/UserRepo";
// import logo from '../logo.png'
import {
	UploaderState,
	FileDataMapper,
	MultipleFileUploader,
	defaultImageExtensions,
} from "../components";
import { imgBaseUrl } from "../api.config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ComponentToPrint from "../components/ComponentToPrint";
import { RouteComponentProps } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
	FileThumbnails,
	defaultThumbnailBuilder,
} from "../components/documentThumbnamil";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./style.css";
const { Paragraph, Text, Title } = Typography;
const moment = require("moment");
const layout = {
	labelCol: {
		span: 6,
	},
	wrapperCol: {
		span: 18,
	},
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};
const state_value = ["DRAFT", "PENDING", "APPROVED", "REJECTED", "DISABLED"];
const color = ["#0d52d0", "#faad14", "#52c41a", "#d9363e", "#0d52d0"];
var files: any = [];
class ViewClaims extends React.Component<any, any> {
	componentRef!: Component<any, {}, any> | Element | null;
	constructor(props: any) {
		super(props);

		this.state = {
			claim: {},
			loading: false,
			status: 10,
			isVisible: false,
			amount: 0,
			description: "",
			invoiceNo: "",
			date: moment().format("YYYY-MM-DD"),
			invoice_date: moment().format("YYYY-MM-DD"),
			fileMapperData: {
				files: [],
				data: [],
			},
			file_names: [],
			checkMsg: true,
			quata: 0,
			totalEntitlement: 0,
			totalRemaining: 0,
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		files = [];
		await this.props.getAllClaimDetailsById(this.props.match.params.id);
		await this.props.getQuataBalance();
		this.setStateVariables();
		await this.props.getSummaryData(this.props.match.params.id);

		var totalAmount = this.props.DashboardRepo.summaryData.dashbordElement.filter(
			(data: any) => data.title == "Total Amount"
		)[0].value;
		var availableAmount =
			this.props.DashboardRepo.summaryData.dashbordElement.filter(
				(data: any) => data.title == "Remaining Balance"
			)[0].value;
		this.setState({
			totalEntitlement: totalAmount,
			totalRemaining: availableAmount,
		});
	}

	setStateVariables() {
		files = [];
		this.setState({
			loading: false,
			claim: this.props.ClaimRepo.allClaimDetails,
			status: this.props.ClaimRepo.allClaimDetails.reimbursementAction.status,
			amount: this.props.ClaimRepo.allClaimDetails.amount,
			description: this.props.ClaimRepo.allClaimDetails.description,
			invoiceNo: this.props.ClaimRepo.allClaimDetails.invoiceNo,
			invoice_date: this.props.ClaimRepo.allClaimDetails.billDate.split("T")[0],
			file_names: [],
			fileMapperData: {
				files: [],
				data: [],
			},
			quata: this.props.ClaimRepo.quata,
		});

		this.props.ClaimRepo.allClaimDetails.relatedDocumeents.map(
			(doc: any, index: number) => files.push({ name: doc.name })
		);

		this.setState({ file_names: files });
	}

	handleOk = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	handleCancle = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	viewImage = (filename: any) => {
		this.setState({
			visible: true,
			filename: filename,
		});
	};

	async clickResubmit(claimId: any) {
		this.setState({ loading: true });
		var res = await this.props.resubmitClaim(claimId);
		if (res.success) {
			const { history } = this.props;
			history.push(`/protected/view-claim/` + res.id);
			window.location.reload();
		} else {
			message.error("error");
		}
		this.setState({ loading: false });
	}

	async clickSendToHR(claimId: any) {
		this.setState({ loading: true });
		var response = await this.props.sendToHrApproval(claimId);
		if (response) {
			window.location.reload();
		} else {
			message.error("Error");
		}
	}

	async clickEditClaim(claimId: any) {
		this.setState({ isVisible: true });
	}

	async deteleClaim(claimId: any) {
		this.setState({ loading: true });
		var response = await this.props.deleteClaim(claimId);
		if (response) {
			this.setState({ loading: false });
			this.props.history.push("/protected/dashboard");
		} else {
			this.setState({ loading: false });
			message.error("Error ");
		}
	}

	async onCancelModal() {
		this.setState({ isVisible: false });
		this.setStateVariables();
	}
	checkMsg = () => {
		this.setState({
			checkMsg: true,
		});
	};

	onFinish = async (values: any) => {
		if (this.chechExistClaims()) {
			message.error("This claim is already exist");
			return;
		} else {
		}
		if (!this.checkEmpty()) {
			{
				this.state.checkMsg && this.setState({ checkMsg: false });
			}
			return;
		}

		this.setState({ loading: true, isVisible: false });

		const form = new FormData();
		var claimDetails = {
			amount: this.state.amount,
			userId: lionUserManager.user.data.USER_ID,
			billDate: this.state.invoice_date,
			description: this.state.description,
			relatedDocumeents: this.state.file_names,
			invoiceNo: this.state.invoiceNo,
		};

		this.state.fileMapperData.files.forEach((element: any) => {
			form.append("images", element);
		});

		form.append("reimbursement", JSON.stringify(claimDetails));
		form.append("status", "1");

		var response = await this.props.updateDrafModeReimbursment(
			form,
			this.props.match.params.id
		);

		if (response) {
			this.setState({
				loading: false,
				date: moment().format("YYYY-MM-DD"),
				invoice_date: moment().format("YYYY-MM-DD"),
				fileMapperData: {
					files: [],
					data: [],
				},
			});
			window.location.reload();
			//this._getData();
		} else {
			message.error("error");
			this.setState({ loading: false });
		}
	};

	chechExistClaims() {
		var existingClaim =
			this.props.DashboardRepo.summaryData.userReimbursements.find(
				(claim: { invoiceNo: any; invoiceDate: any; billDate: any }) =>
					claim.billDate === this.state.invoice_date + "T05:30:00.000+0530" &&
					claim.invoiceNo === this.state.invoiceNo
			);

		if (existingClaim != undefined) {
			return true;
		}
		return false;
	}

	checkEmpty() {
		if (this.state.amount == "") {
			message.error("Amount cannot be empty", 2.5, this.checkMsg);
			return false;
		}
		if (this.state.invoice_date == "") {
			message.error("Invoice date cannot be empty", 2.5, this.checkMsg);
			return false;
		}
		if (this.state.description == "") {
			message.error("Description cannot be empty", 2.5, this.checkMsg);
			return false;
		}
		if (this.state.invoiceNo == "") {
			message.error("Invoice No cannot be empty", 2.5, this.checkMsg);
			return false;
		}

		if (
			this.state.fileMapperData.data.length == 0 &&
			this.state.file_names.length == 0
		) {
			message.error("Please upload relevant image", 2.5, this.checkMsg);
			return false;
		}

		return true;
	}

	onFinishFailed = async (values: any) => {};

	onChangeAmount = async (e: any) => {
		var Amount = e.target.value.replace(",", "");
		var remainQuata = this.state.quata;

		var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
		var checkValue = Amount.match(regex);

		if (checkValue == null && Amount != "") {
			{
				this.state.checkMsg &&
					message.error(
						"Enter Valid Amount. For example: Rs .523.36",
						2.5,
						this.checkMsg
					) &&
					this.setState({ checkMsg: false });
			}
			this.setState({
				amount: "",
			});
			return;
		}

		if (Amount <= 0 || Amount == "") {
			message.error("Please Enter Valid Amount");
		}

		if (remainQuata < Number(Amount)) {
			message.error("No quota available");
			this.setState({
				amount: "",
			});
			return;
		}
		this.setState({
			amount: Amount,
		});
	};

	changeInvoiceDate = (date: any, dateString: any) => {
		var currnetDate = new Date(this.state.date).getTime(); // gives 1486492200000
		var invoiceDate = new Date(dateString).getTime();

		if (currnetDate < invoiceDate) {
			message.error("Please Add The Correct Date");
			this.setState({
				invoice_date: moment().format("YYYY-MM-DD"),
			});
			return;
			// your code goes here
		}

		this.setState({
			invoice_date: dateString,
		});
	};

	onChangeDescription = async (values: any) => {
		this.setState({
			description: values.target.value,
		});
	};

	onChangeInvoiceNo = (e: any) => {
		this.setState({
			invoiceNo: e.target.value,
		});
	};

	onMultipleUploaderSubmit = async (data: any) => {
		this.setState({
			fileMapperData: data,
		});
	};

	disabledDate(current: any) {
		return current && current < moment().subtract(3, "months");
	}

	removeItem = (index: any, filename: any) => {
		const files = [...this.state.file_names];

		files.splice(index, 1);

		this.setState({
			file_names: files,
		});

		return;
	};

	render() {
		const { claim } = this.state.claim;

		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<Row>
						<Col span={this.state.status == 3 ? "20" : "20"}>
							<Row>
								<Title
									style={{
										fontSize: 15,
										marginTop: 24,
										fontWeight: "bold",
										color: "#000",
									}}>
									Claim Summary
								</Title>
								<Col>
									{this.state.status == 1 && (
										<div style={{ marginTop: 22, marginLeft: 10 }}>
											<ReactToPrint
												trigger={() => {
													// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
													// to the root node of the returned component as it will be overwritten.
													return (
														<a href='#' type='primary'>
															Print this
														</a>
													);
												}}
												content={() => this.componentRef}
											/>
											<div style={{ display: "none" }}>
												{" "}
												<ComponentToPrint
													data={this.state.claim}
													ref={(el) => (this.componentRef = el)}
												/>
											</div>
										</div>
									)}
								</Col>
							</Row>
						</Col>

						<Col>
							{this.state.status == 3 ? (
								<Button
									onClick={() => this.clickResubmit(this.state.claim.id)}
									style={{ marginTop: 24 }}
									type='primary'>
									Resubmit Claim
								</Button>
							) : (
								<div />
							)}
							{this.state.status == 0 ? (
								<Row>
									<Button
										onClick={() => this.clickSendToHR(this.state.claim.id)}
										style={{ marginTop: 24 }}
										type='primary'>
										Send To HR Approval
									</Button>
								</Row>
							) : (
								<div />
							)}
						</Col>
					</Row>
					{this.state.claim && (
						<div style={{ opacity: this.state.loading ? 0.8 : 1, marginTop: 20 }}>
							<Descriptions bordered size={"small"}>
								<Descriptions.Item label='Claim Id'>
									{this.state.claim.id}
								</Descriptions.Item>
								<Descriptions.Item label='Ref No'>
									{this.state.claim.referenceNo}
								</Descriptions.Item>
								<Descriptions.Item label='Total Amount'>
									{" "}
									Rs.{" "}
									{(Math.round(this.state.claim.amount * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Descriptions.Item>
								{this.state.claim.billDate && (
									<Descriptions.Item label='Bill Date ( YY:MM:DD )'>
										{this.state.claim.billDate.split("T")[0]}
									</Descriptions.Item>
								)}
								{this.state.claim.invoiceNo && (
									<Descriptions.Item label='Invoice No '>
										{this.state.claim.invoiceNo}
									</Descriptions.Item>
								)}
								{this.state.claim.createOn && (
									<Descriptions.Item label='Created Date ( YY:MM:DD )'>
										{this.state.claim.createOn.split("T")[0]}
									</Descriptions.Item>
								)}
								{this.state.claim.reimbursementAction && (
									<Descriptions.Item label='Status'>
										{" "}
										<Badge
											count={state_value[this.state.claim.reimbursementAction.status]}
											style={{
												backgroundColor: color[this.state.claim.reimbursementAction.status],
											}}
										/>{" "}
									</Descriptions.Item>
								)}
								{this.state.claim.relatedDocumeents && (
									<Descriptions.Item label='Document Count'>
										{this.state.claim.relatedDocumeents.length}
									</Descriptions.Item>
								)}
							</Descriptions>
							<h4 style={{ marginTop: 20, color: "#c0c0c0" }}>
								<b>Description : </b>
								{this.state.claim.description == ""
									? "No Description"
									: this.state.claim.description}
							</h4>

							{this.state.claim.reimbursementAction &&
								this.state.claim.reimbursementAction.status == 3 && (
									<div style={{ marginTop: 24 }}>
										<Col span='20'>
											<Title
												style={{
													fontSize: 15,
													marginTop: 24,
													fontWeight: "bold",
													color: "#e60d18",
												}}>
												Rejected Reason
											</Title>
										</Col>
										<Descriptions bordered style={{ marginTop: 10 }}>
											<Descriptions.Item style={{ color: "#e60d18" }}>
												{this.state.claim.reimbursementAction.comment}
											</Descriptions.Item>
										</Descriptions>
									</div>
								)}
							{this.state.claim.relatedDocumeents &&
								this.state.claim.relatedDocumeents.length > 0 && (
									<div style={{ marginTop: 24 }}>
										<Col span='20'>
											<Title
												style={{
													fontSize: 15,
													marginTop: 24,
													fontWeight: "bold",
													color: "#000",
												}}>
												Related Documents
											</Title>
										</Col>
									</div>
								)}
							<Row>
								{/* {this.state.claim.relatedDocumeents &&
									this.state.claim.relatedDocumeents.map((doc: any) => (
										<a onClick={() => this.viewImage(doc.name)}>
											<Avatar
												src={imgBaseUrl + doc.name}
												size={150}
												style={{ margin: 2 }}
											/>
										</a>
									))} */}
                  {this.state.claim.relatedDocumeents && (
										<FileThumbnails
											thumbnailBuilder={(file) =>
												defaultThumbnailBuilder({
													file,
													overlay: (
														<Menu>
															<Menu.Item
																icon={<CloudDownloadOutlined />}
																onClick={() => window.open(file.src)}>
																Download
															</Menu.Item>
															{/* <Menu.Item
																danger
																icon={<DeleteOutlined />}
																onClick={() => this.removeItem(file.meta?.index, file.name)}>
																Delete
															</Menu.Item> */}
														</Menu>
													),
												})
											}
											files={this.state.claim.relatedDocumeents.map((doc: any, i: any) => ({
												name: doc.name,
												src: `${imgBaseUrl}${doc.name}`,
												meta: { index: i, ...doc },
											}))}
										/>
									)}
							</Row>
							<Col>
								{this.state.status == 0 ? (
									<Row>
										<Popconfirm
											title='Are you sure to delete this claim?'
											onConfirm={() => this.deteleClaim(this.state.claim.id)}
											okText='Yes'>
											<a style={{ margin: 24, color: "#d9363e" }} type='default'>
												Delete Claim
											</a>
										</Popconfirm>
										<a
											onClick={() => this.clickEditClaim(this.state.claim.id)}
											style={{ marginTop: 24 }}
											type='default'>
											Edit Claim
										</a>
									</Row>
								) : (
									<div />
								)}
							</Col>
						</div>
					)}
				</Spin>

				<Modal
					width={650}
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancle}
					footer={[]}>
					<div style={{ margin: "auto" }}>
						<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
							Download Image
						</Button>
						<TransformWrapper>
							<TransformComponent>
								<img
									style={{ minWidth: 600, maxWidth: 600 }}
									src={imgBaseUrl + this.state.filename}
									alt='test'
								/>
							</TransformComponent>
						</TransformWrapper>
					</div>
				</Modal>

				<Modal
					title={"Edit Claim "}
					style={{ top: 20 }}
					visible={this.state.isVisible}
					onCancel={() => this.onCancelModal()}
					destroyOnClose={true}
					footer={null}>
					<Form
						{...layout}
						initialValues={{
							remember: false,
						}}
						onFinishFailed={this.onFinishFailed}>
						<Form.Item
							label='Amount'
							rules={[
								{
									required: true,
									message: "Please input your amount!",
								},
							]}>
							<Input value={this.state.amount} onChange={this.onChangeAmount} />

							{/* <p style={{fontSize:10}}>sasasa</p> */}
						</Form.Item>

						{this.state.totalEntitlement != "10,000,000.00" && (
							<Form.Item label='Entitlement' name='entitlement'>
								<Input placeholder={"Rs. " + this.state.totalEntitlement} disabled />
							</Form.Item>
						)}
						{this.state.totalEntitlement != "10,000,000.00" && (
							<Form.Item label='Remaining' name='remainingAmount'>
								<Input placeholder={"Rs. " + this.state.totalRemaining} disabled />
							</Form.Item>
						)}

						<Form.Item
							label='Invoice Date'
							rules={[
								{
									required: true,
									message: "Please input your Invoice Date!",
								},
							]}>
							<DatePicker
								onChange={this.changeInvoiceDate}
								disabledDate={this.disabledDate}
								allowClear={false}
								value={moment(this.state.invoice_date, "YYYY-MM-DD")}
							/>
						</Form.Item>
						<Form.Item
							label='Invoice Number '
							rules={[
								{
									required: true,
									message: "Please input your Invoice Number!",
								},
							]}>
							<Input
								maxLength={200}
								value={this.state.invoiceNo}
								onChange={this.onChangeInvoiceNo}
							/>
						</Form.Item>
						<Form.Item
							label='Description '
							rules={[
								{
									required: true,
									message: "Please input your description!",
								},
							]}>
							<Input
								maxLength={200}
								value={this.state.description}
								onChange={this.onChangeDescription}
							/>
						</Form.Item>
						{this.state.file_names.length > 0 && (
							<div>
								<h5>Related Documents</h5>

								<Row style={{ margin: 14 }}>
									{/* {this.state.file_names.length > 0 &&
										this.state.file_names.map((doc: any, index: number) => (
											<div className='container'>
												<img
													src={imgBaseUrl + doc.name}
													alt='Snow'
													style={{ width: "100%" }}
												/>
												<button
													onClick={() => this.removeItem(index, doc.name)}
													className='btn'>
													Remove
												</button>
											</div>
										))} */}
                    
									{this.state.file_names.length > 0 && (
										<FileThumbnails
											thumbnailBuilder={(file) =>
												defaultThumbnailBuilder({
													file,
													overlay: (
														<Menu>
															<Menu.Item
																icon={<CloudDownloadOutlined />}
																onClick={() => window.open(file.src)}>
																Download
															</Menu.Item>
															<Menu.Item
																danger
																icon={<DeleteOutlined />}
																onClick={() => this.removeItem(file.meta?.index, file.name)}>
																Delete
															</Menu.Item>
														</Menu>
													),
												})
											}
											files={this.state.file_names.map((doc: any, i: any) => ({
												name: doc.name,
												src: `${imgBaseUrl}${doc.name}`,
												meta: { index: i, ...doc },
											}))}
										/>
									)}
								</Row>
							</div>
						)}

						<MultipleFileUploader
							accept={defaultImageExtensions}
							onChange={this.onMultipleUploaderSubmit}
						/>
						<Form.Item>
							<Button type='primary' onClick={this.onFinish}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

const WrappedViewClaims = ViewClaims;

const withClaimRepo = connect(
	(state: any) => state,
	ClaimRepo
)(WrappedViewClaims);
export default withRouter(withClaimRepo as FunctionComponent);
