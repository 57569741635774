import React, { Component } from 'react';
import { Table, Descriptions, Radio   } from 'antd';
const moment = require('moment');
 
interface ClaimDetail {
    data :   any , 
}

const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    }, 
   {
      title: 'InvoiceDate',
      dataIndex: 'billDate',
      render: (invoiceDate : any) => invoiceDate.split("T")[0]
    },
    {
      title: 'Note',
      dataIndex: 'description',
    },{
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount : any) => "Rs. "+(Math.round( amount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  ];

export default class ComponentToPrint extends React.Component<ClaimDetail> {
    render() {
        return (
            <div style ={{ margin : 10}}>
               <h2 style ={{ padding : 10}}>LION BREWERY (CEYLON) PLC </h2>
                 {this.props.data &&  <Descriptions style ={{ padding : 10}} bordered title="Club Membership Claim Report" size={"small"}>
                  <Descriptions.Item  span={22} label="Employee Id">{this.props.data.user.userDetails.employeeNumber}</Descriptions.Item>
                  <Descriptions.Item  span={22} label="Ref No ">{this.props.data.referenceNo}</Descriptions.Item>
                 
                  {this.props.data.user && <Descriptions.Item  span={22} label="Employee Name">{this.props.data.user.name}</Descriptions.Item>  }
                  <Descriptions.Item span={22} label="Total Amount">{"Rs. "+(Math.round( this.props.data.amount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</Descriptions.Item> 
                  <Descriptions.Item span={22} label="Note">{this.props.data.description == "" ? "No Description": this.props.data.description }</Descriptions.Item> 
                  <Descriptions.Item  span={22} label="Invoice Date">{this.props.data.billDate.split("T")[0]}</Descriptions.Item>
                  <Descriptions.Item span={22} label="Created Date">{this.props.data.createOn.split("T")[0]  }</Descriptions.Item> 
                 
             
        </Descriptions> }
          {/* <Table pagination={{ pageSize: 10 }} style={{marginTop:20}}  columns={columns} dataSource={this.props.data} /> */}
          <div style={{marginTop:40 ,marginLeft:20}}>
          <h3>...........................................</h3>
          <p style={{  fontSize:18}}>{this.props.data.user.firstName + " " + this.props.data.user.lastName}</p>
          <p style={{  fontSize:18}}>{moment().format('YYYY-MM-DD')}</p>
          </div>
          </div>
        );
      }
}