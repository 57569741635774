
const auth = btoa("OAUTH_DEMO_CLIENT_ID:hello")

var _baseUrl = "";

if (process.env.NODE_ENV == 'production') {
    // change this when going production
     _baseUrl = "https://lionapi.eimsky.com"
    //  _baseUrl = "https://lionapi-test.eimsky.com"
    // _baseUrl = "https://13.89.41.122:9762"
}

export const baseUrl = _baseUrl; 
 export const imgBaseUrl = `${baseUrl}/clubMembership/downloadFile/`; 
 export const fileDownloadUrl = "https://lionapi.eimsky.com/clubMembership/downloadFile/documents/"; 
 
export const headers = {
    "Content-Type": "application/javascript",
    "Authorization": "Basic " + auth,
    "cache-control": "no-cache",
}
 