import React from 'react';
import { Layout } from 'antd';

interface DefaultLayoutProps {
    sidebar: React.ReactNode;
    header: React.ReactNode;
    body?: React.ReactNode;
    isSidebarCollapsed?: boolean;
}

export const DefaultMediaSpans = {
    xl: 6,
    xs: 24,
    sm: 24,
    lg: 12,
}

export default class DefaultLayout extends React.Component<DefaultLayoutProps, {}>{
    render() {
        return (
            <Layout style={{ height: '100vh' }}>
                <Layout.Sider trigger={null} collapsible collapsed={this.props.isSidebarCollapsed}>
                    {this.props.sidebar}
                </Layout.Sider>
                <Layout>
                    <Layout.Header style={{ background: '#fff', paddingLeft: 0 }}>
                        {this.props.header}
                    </Layout.Header>
                    <Layout.Content style={{ padding: '0 50px' }}>
                        {this.props.body}
                        {this.props.children}
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}