import React, { FC } from "react";
import { Image, Row, Col, Dropdown, Menu } from "antd";
import {
	CloudDownloadOutlined,
	EyeOutlined,
	FileOutlined,
	MoreOutlined,
	DeleteOutlined,
} from "@ant-design/icons";

interface FileThumbnailsFile {
	name: string;
	src: string;
	meta?: any
}

interface FileThumbnailsProps {
	files: FileThumbnailsFile[];
	thumbnailBuilder: (file: FileThumbnailsFile) => any;
}

export const FileThumbnails: FC<FileThumbnailsProps> = (props) => {
	return (
		<>
			<Row gutter={5}>
				{props.files.map((file) => (
					<Col flex='auto'>{props.thumbnailBuilder(file)}</Col>
				))}
			</Row>
		</>
	);
};

interface FileThumbnailProps {
	preview: any;
	fileName: any;
	overlay?: any;
}

const FileThumbnail: FC<FileThumbnailProps> = (props) => {
	return (
		<Row
			align='middle'
			justify='space-between'
			style={{
				minWidth: 250,
				border: "1px solid #eee",
				borderRadius: 5,
				margin: "3px 0",
				padding: 5,
			}}>
			<Col>{props.preview}</Col>
			<Col flex='auto' style={{ margin: "0 10px" }}>
				<span>{props.fileName}</span>
			</Col>
			<Col>
				{props.overlay && (
					<Dropdown overlay={props.overlay}>
						<MoreOutlined />
					</Dropdown>
				)}
			</Col>
		</Row>
	);
};

const PdfFileThumbnail: FC<Omit<FileThumbnailProps, "preview">> = (props) => {
	return (
		<FileThumbnail
			{...props}
			preview={
				<div
					style={{
						height: 50,
						width: 50,
						borderRadius: 3,
						border: "1px solid #eee",
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
					}}>
					<FileOutlined style={{ fontSize: 20, color: "#555" }} />
				</div>
			}
		/>
	);
};

function getExtension(fileName: string) {
	if (fileName) return `.${fileName.split(".")[1]}`;
	return "";
}

interface DefaultThumnailBuilderProps {
	file: FileThumbnailsFile;
	overlay?: any;
}

export const defaultThumbnailBuilder: FC<DefaultThumnailBuilderProps> = ({
	file,
	overlay,
}) => {
	switch (getExtension(file.name)) {
		case ".pdf":
			return <PdfFileThumbnail fileName={file.name} overlay={overlay} />;
		default:
			return (
				<FileThumbnail
					fileName={file.name}
					overlay={overlay}
					preview={
						<div style={{ width: 50, textAlign: "center" }}>
							<Image
								src={file.src}
								height={50}
								style={{ display: "inline" }}
								preview={{
									src: file.src,
									mask: <EyeOutlined />,
								}}
							/>
						</div>
					}
				/>
			);
	}
};

// export default function App() {
// 	return (
// 		<FileThumbnails
// 			thumbnailBuilder={defaultThumnailBuilder}
// 			file={[
// 				{ name: "png.png", src: "https://via.placeholder.com/150x200/200" },
// 				{ name: "Anguage_features.pdf", src: "anguage features.pdf" },
// 				{
// 					name:
// 						"test.pdf",
// 					src: "pdf.pdf",
// 				},
// 				{ name: "pdf.pdf", src: "pdf.pdf" },
// 				{ name: "pdf.pdf", src: "pdf.pdf" },
// 				{ name: "pdf.pdf", src: "pdf.pdf" },
// 				{ name: "pdf.pdf", src: "pdf.pdf" },
// 				{ name: "pdf.pdf", src: "pdf.pdf" },
// 			]}
// 		/>
// 	);
// }
