import React from 'react';
import { Avatar } from 'antd';
import User from 'user-manager';
import { ConfigConsumerProps } from 'antd/lib/config-provider';

export default class DefaultAvatar extends React.Component<{ user: User } | any> {
    renderAvatar(props: ConfigConsumerProps){

    }
    render() {
        const name: String = this.props.user ? this.props.user.name : undefined
        return (

            // <Row type="flex" justify="space-between" align="middle" gutter={10}>
            //     <Col>
            //         <div>{name}</div>
            //     </Col>
            //     <Col>
                    <Avatar src={ this.props.user.data.User.imagePaths} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{name && name.charAt(0).toUpperCase()}</Avatar>
            //     </Col>
            // </Row>

        );
    }
}