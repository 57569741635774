import React, { Component, FunctionComponent } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { message, Typography, Row, Col, Tooltip } from "antd";
import { withRouter } from "react-router";
import { UploaderState } from "../components";
import { RcFile } from "antd/lib/upload";
import { ClaimRepo } from "../repos/ClaimRepo";
import logo from "../../images/doc.png";
import { Select } from "antd";
import { fileDownloadUrl } from "../api.config";
class UploadDocuments extends React.Component<any> {
	constructor(props: any) {
		super(props);
		this.state = {
			file: undefined,
			filterData: [],
			description: "",
			reason: "",
			isLimitedEdition: false,
			fileMapperData: {
				files: [],
				data: [],
			},
			validation: {
				filters: false,
				reason: true,
				comment: true,
				file: false,
			},
		};
	}

	async componentDidMount() {
		console.log("/////////////////////////");
		await this.props.getUploadedDocuments();
		console.log(this.props.ClaimRepo.documents);
	}

	versionTypeChange = (value: any) => {
		value == 1
			? this.setState({ isLimitedEdition: true })
			: this.setState({ isLimitedEdition: false });
	};

	render() {
		// const mainCats = normalizeMainCat(this.props.GlobalFilterRepo.filters).map(normalize);
		return (
			<div>
				{
					<Row style={{ marginTop: 15 }} gutter={[10, 10]}>
						{this.props.ClaimRepo.documents &&
							this.props.ClaimRepo.documents.map((doc: any) => (
								<Tooltip title={doc.name}>
									<Col xl={3} xs={3} sm={3} lg={3} key={doc.id}>
										<a href={fileDownloadUrl + doc.name}>
											<div className='doc-container'>
												<img src={logo} alt='img' style={{ width: "80%", height: "60%" }} />
												<p style={{ marginLeft: 20, color: "#000" }}>
													{doc.name.substring(0, 22)}
												</p>
											</div>
										</a>
									</Col>
								</Tooltip>
							))}
					</Row>
				}
			</div>
		);
	}
}

const WrappedDocView = UploadDocuments;
const withClaimRepo = connect((state: any) => state, ClaimRepo)(WrappedDocView);
export default withRouter(withClaimRepo as FunctionComponent);
