import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { tagCardData: null,quata:null, reimbursements : null ,summaryData: null , userHistory : null }, action: { type: DashboardActionTypes, payload: any }) => {
    switch (action.type) {
        case DashboardActionTypes.GET_ALL_TAG_CARD_DATA: {
            return { ...state, tagCardData: action.payload }
        }
        case DashboardActionTypes.GET_USER_HISTORY: {
            return { ...state, userHistory: action.payload }
        }
        case DashboardActionTypes.GET_USER_SUMMARY: {
            return { ...state, summaryData: action.payload }
        }
        case DashboardActionTypes.GET_QUATA_BALANCE: {
            return { ...state, quata: action.payload }
        }
        case DashboardActionTypes.GET_QUATA_BALANCE: {
            return { ...state, quata: action.payload }
        }
        case DashboardActionTypes.GET_USER_REIMBURSEMENT: {
            return { ...state, reimbursements: action.payload }
        }
        default: return state;
    }
}

export const DashboardActions = (dispatch: any) => ({
 

    getSummaryData: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/clubMembership/dashboard/get/" + lionUserManager.user.data.USER_ID);
  
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_USER_SUMMARY,
                    payload: res.data.dashbordElement
                });

                dispatch({
                    type: DashboardActionTypes.GET_USER_REIMBURSEMENT,
                    payload: res.data.userReimbursements
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getQuataBalance: async (claimId : any) => {
       
        try {
               const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/getRemainingBalance/' + lionUserManager.user.data.USER_ID);
                         
              if (res.status == 200) {  
                dispatch({
                    type: DashboardActionTypes.GET_QUATA_BALANCE,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
           
            throw err;
        }
    },
 

    getUserHistory: async (status: any, count : any) => {
        try {
            const form = new FormData();
            form.append("userId", lionUserManager.user.data.USER_ID);
            form.append("status", status);
            form.append("count", count);
            const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/get', form); 
            if (res.status == 200) {
              
                dispatch({
                    type: DashboardActionTypes.GET_USER_HISTORY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    createClaim: async (claims: any) => {
         
        try {
            const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/create', claims);
          
            if (res.status == 201) {
                return true;
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
})

export class DashboardActionTypes {
    static GET_ALL_TAG_CARD_DATA = 'GET_ALL_TAG_CARD_DATA';
    static GET_USER_SUMMARY = 'GET_USER_SUMMARY';
    static GET_USER_HISTORY = 'GET_USER_HISTORY';
    static ARTWORK_DASHBOARD_SUMMARY = 'ARTWORK_DASHBOARD_SUMMARY'
    static GET_QUATA_BALANCE= 'GET_QUATA_BALANCE'
    static GET_USER_REIMBURSEMENT = 'GET_USER_REIMBURSEMENT'
}