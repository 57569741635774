import React, { FunctionComponent } from 'react';
import { Table, Col, message,Spin , Drawer,Typography ,Input, Descriptions , Row, Avatar ,Modal , Button , } from 'antd';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { ClaimRepo } from '../repos/ClaimRepo'; 
import { lionUserManager } from '../repos/UserRepo'
// import logo from '../logo.png' 
import './style.css'; 
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"; 
import { imgBaseUrl } from '../api.config'
const { Paragraph, Text } = Typography;
const { Column } = Table;
const { TextArea } = Input;
 
class SapPending extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
              claims : [],
              loading : false,
               
         };
    }

    componentDidMount() {

        this._getData()
    }

    async  _getData() {
          this.setState({loading : true})
          await this.props.getPendigPaymentList(); 
        this.setState({loading : false})
  
        
    }
 
 
 
   
  sendForPayment = async () => {
         
        this.setState({ loading: true })
        var res = await this.props.sendForPayment();
        if (res) {
        window.location.reload();
        }
        else {
        message.error("error");
        }
        this.setState({ loading: false })
    }
 

    render() {


        return (
            <div >
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}>   
                 <div style={{opacity : this.state.loading ? 0.8 : 1}}>
                 <Row>
                    <Col span={21}><h3>Pending Payments  </h3></Col>
                    <Col span={3} >
                       <Button onClick={ this.sendForPayment} type="default">Send For Payment</Button>
                    </Col>
                    </Row>
 
               {this.props.ClaimRepo.pendingPayments && <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.props.ClaimRepo.pendingPayments.reverse()}> 
                    <Column title="Claim ID" key="referenceNo"   dataIndex="referenceNo" />
                    <Column width="100" title="Employee Id" key="user"   dataIndex="user" render={(value, item, index) => (  value.userDetails.employeeNumber )}/>
                    <Column width="100" title="User" dataIndex="user" key="user" render={(value, item, index) => (  value.userDetails.firstName + " " + value.userDetails.lastName )}/>
                    <Column width="100" title="Designation" dataIndex="user" key="user" render={(value, item, index) => (  value.designationName )}/>
                    <Column title="Amount" dataIndex="amount" key="amount" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/> 
                      </Table> }
                    </div> 
                    </Spin>
            </div>
        );
    }
}

const WrappedApproveClaim = SapPending;
const withDashboardRepo = connect(((state: any) => state), ClaimRepo)(WrappedApproveClaim);
export default withRouter(withDashboardRepo as FunctionComponent); 
 