import React from 'react';
import { Breadcrumb } from 'antd';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { rootComponent, rootIcon } from '../../route.config';
import './style.css';
import { Link } from 'react-router-dom';
import {
    RightOutlined,
    HomeOutlined
} from '@ant-design/icons'

export class CustomBreadCrumbSeparator extends React.Component {
    render() {
        return <Breadcrumb.Separator>
            <RightOutlined />
        </Breadcrumb.Separator>
    }
}

interface RouterLocationProps {
    match: String
    location: Location,
    history: String
}

class ShowTheLocation extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    render() {
        const currentLocation: RouterLocationProps = this.props as RouterLocationProps;

        return <Breadcrumb style={{ margin: '16px 0' }} separator={<CustomBreadCrumbSeparator />}>

            <Breadcrumb.Item>
                <span style={{width: 15, display: 'inline-block'}}><HomeOutlined /></span>
                {rootComponent}
            </Breadcrumb.Item>

            {
                currentLocation.location.pathname.split("/").map((chunk: string, i: number, _) =>
                    <Breadcrumb.Item key="chunk">{normalizeBreadcrumbItem(chunk)}</Breadcrumb.Item>
                )
            }
        </Breadcrumb>
    }
}
export const normalizeBreadcrumbItem = (item: String) => {
    return item.replace(/([-_][a-z])/ig, ($1) => {
        return $1
            .replace('-', ' ')
            .replace('_', ' ');
    })
};
export const ShowTheLocationWithRouter = withRouter(ShowTheLocation as any);