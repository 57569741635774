import React, { FunctionComponent } from 'react';
import { Button ,message } from 'antd';
import { connect } from 'react-redux';
import { DashboardActions } from '../repos/DashboardRepo'; 
import  Button1  from '../components/com'
 
 
 
class Test extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
        }
    }
 

    componentDidMount() {
        
      }

      aa = ()=> {
        message.success("ado");
    }

    eventhandler = ( data : any) =>{
       console.log(data)
    }
    callbackFunction = (childData : any) => {
        console.log(childData.username)
  } 
    

   render() {

        return ( 
                 <div>
 
                     <Button1 parentCallback = {this.callbackFunction}/> 
      
      </div> 
        )
    }
}





 

const WrappedDashboardView = Test;
const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);

export default withDashboardRepo;
 