import React, { FunctionComponent } from 'react'; 
import {  Table, Spin, Badge  ,Tag  } from 'antd';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { ClaimRepo } from '../repos/ClaimRepo'; 
// import logo from '../logo.png' 
import './style.css';  
import {FundViewOutlined} from '@ant-design/icons';

const { Column } = Table;
const state_value = [ "DRAFT", "PENDING" ,"APPROVED" ,"REJECTED" , "DISABLED"  ]
 
const color = ["#0d52d0" , "#faad14","#52c41a" , "#d9363e" ,"#0d52d0"]
 
class ViewAllClaims extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
              claims : [],
              loading : false,
              visibleView: false,  
         };
    }

    componentDidMount() {

        this._getData()
    }

    async  _getData() {
        this.setState({loading : true})
        await this.props.getUserHistory();   
        console.log(this.props.ClaimRepo.userSummary)
        this.setState({loading : false , claims : this.props.ClaimRepo.userSummary.reverse()})
 
    }
 
    render() { 
       
        return (
            <div >
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}>   
                 <div style={{opacity : this.state.loading ? 0.8 : 1}}>

               {this.state.claims &&  <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claims.reverse() }> 
                    <Column title="Claim ID" key="referenceNo"  dataIndex="referenceNo"  render={(value, item, index) => value}  />
                    <Column title="Amount " dataIndex="amount" key="amount" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/>
                    <Column width="30%" title="Description " dataIndex="description" key="description" />
                    <Column width="200" title="Bill Date"   key="billDate"  render={(value, item, index) => (  value.billDate.split("T")[0]  )}/>
                    <Column width="200" title="Created Date"   key="creatdOn"  render={(value, item, index) => (  value.createOn.split("T")[0]  )}/>
                    <Column width="100" title="Status"    render={(value, item, index) => (
                        // value.status != 1 ?
                        //         <div>
                        //               <Badge count={" DISCUSSED" } style={{ backgroundColor: "#52c41a" }}/>    
                                             
                        //         </div> :  <div>
                                          
                        //             <Badge count={"PENDING"} style={{ backgroundColor: "#faad14" }}/>  
                        //                   </div>
                        //     )}/>
                        <Badge count={state_value[value.reimbursementAction.status]} style={{width:70, backgroundColor: color[value.reimbursementAction.status] }}/>
                        )}/>
                    <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <div>
                                      <Link  to={`view-claim/${value.id}`}> 
                                            <span>
                                            <a > <Tag color="#875ec4"> View</Tag> </a>
                                            </span>
                                       </Link>
                                </div>
                            )}
                            />
                </Table> }
                 
                </div> 
                    </Spin>
            </div>
        );
    }
}

const WrappedViewAllClaims = ViewAllClaims;
const withDashboardRepo = connect(((state: any) => state), ClaimRepo)(WrappedViewAllClaims);
export default withDashboardRepo;