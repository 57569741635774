export class RouteConfig{
    static root = '/'

    static PROTECTED = '/protected'
    static PROTECTED_ROOT = '/protected/'
    static PROTECTED_MAIN_DASHBOARD = '/protected/dashboard'
    static PROTECTED_HOME = '/protected/home'
    static PROTECTED_CREATE = '/protected/create'
    static PROTECTED_REGISTER_VEHICLE = '/protected/register'
    static PROTECTED_VIEW_ALL= '/protected/view-all'
    static PROTECTED_CLAIM= '/protected/view-claim/:id'
    static PROTECTED_APPROVE_CLAIMS= '/protected/approve-claims'
	static PROTECTED_PENDING_SAP= '/protected/pending-items-for-sap'
	static PROTECTED_DOCUMENTS= '/protected/documents'
	static PROTECTED_APPROVED_CLAIMS= '/protected/approved_claims'
    

    static LOGIN = '/'
}

//this applies to breadcrumbs
export const rootComponent = "Home"
export const rootIcon = "home"

