import React, { Component } from 'react';
import { Row, Col, Button } from 'antd'
import { CarOutlined } from '@ant-design/icons'
import { Vehicle } from '../../repos/models/Vehicle';

export interface VehicleCardProps {
    vehicle: Vehicle
    onClick: () => void
}

export default class extends Component<VehicleCardProps>{
    componentDidMount() {
        console.log(this.props)
    }
    // {this.props.vehicle.plateNumber}
    render() {
        return (
            <Row className="vehicle-card-container" justify="center" align="middle">
                <Col >
                    <CarOutlined style={{ fontSize: 25, color: '#009688' }} />
                </Col>
                <Col flex="auto" style={{ padding: '0 15px' }}>
                    <Row style={{ fontSize: 15, fontWeight: 600, lineHeight: 1 }}>{this.props.vehicle.plateNumber}</Row>
                    <Row style={{ fontSize: 13, lineHeight: 1.5 }}>{`${this.props.vehicle.engineCapacity.name}: ${this.props.vehicle.fuelType.name}`}</Row>
                </Col>
                <Col >
                    <Button type="primary" shape="round" onClick={this.props.onClick} style={{ fontSize: 12, fontWeight: 600 }}>Change Vehicle</Button>
                </Col>
            </Row>
        )
    }
}