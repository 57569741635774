import React from 'react';
import ContentPresenter from './ContentPresenter';

export class Page extends React.Component{
    render(){
        return (
            <ContentPresenter>
                {this.props.children}
            </ContentPresenter>
        )
    }
}

export { ContentPresenter }