import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { userSummary : null ,approverdClaimsForReport : null, documents:null, pendingPayments : null   , quata : null, allClaims : null , summaryData:null , allClaimDetails : null  }, action: { type: ClaimActionTypes, payload: any }) => {
    switch (action.type) {  
        case ClaimActionTypes.GET_ALL_CLAIMS: {
            return { ...state, allClaims: action.payload }
        }    
        case ClaimActionTypes.GET_All_Details_CLAIM_BY_ID: {
            return { ...state, allClaimDetails: action.payload }
        }
        case ClaimActionTypes.GET_USER_HISTORY: {
            return { ...state, userSummary: action.payload }
        } 

        case ClaimActionTypes.GET_QUATA_BALANCE: {
            return { ...state, quata: action.payload }
        }
        case ClaimActionTypes.GET_USER_SUMMARY: {
            return { ...state, summaryData: action.payload }
        }
        case ClaimActionTypes.GET_PENDIGN_PAYMENT: {
            return { ...state, pendingPayments : action.payload }
		}
		case ClaimActionTypes.DOCUMENTS: {
            return { ...state, documents : action.payload }
		}
		case ClaimActionTypes.APPROVED_CLAIM_REPORT_DATA: {
            return { ...state, approverdClaimsForReport: action.payload }
		}
		

        
        default: return state;
    }
}

export const ClaimRepo = (dispatch: any) => ({

 
    resubmitClaim: async (claimId : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/resubmit/'+ claimId , {});
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                      "id" : res.data.Id
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
 
    updateDrafModeReimbursment: async (claims : any , reimbursmentId : any) => {
        try { 
               //   const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/edit/' + reimbursmentId,  claims);
                 const res = await lionUserManager.authenticationManager.call('put' ,'/clubMembership/reimbursement/edit/' + reimbursmentId,  claims);
               
               if (res.status == 201) {
                return true;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                return false;
            }
        } catch (err) { 
            return false;
            throw err;
        }
    },

    getAllClaims: async () => {
        try {
               const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/getPandings');
        
              if (res.status == 200) {  
                dispatch({
                    type: ClaimActionTypes.GET_ALL_CLAIMS,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
           
            throw err;
        }
    },


   
     
    
    getAllClaimDetailsById: async (claimId : any) => {
       
        try {
               const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/get/' + claimId);
                    
              if (res.status == 200) {   
                dispatch({
                    type: ClaimActionTypes.GET_All_Details_CLAIM_BY_ID,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
           
            throw err;
        }
    },

    getSummaryData: async (claimId: any) => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/clubMembership/dashboard/get/" + lionUserManager.user.data.USER_ID +"/"+ claimId);
 
            if (res.status == 200) {   
                dispatch({
                    type: ClaimActionTypes.GET_USER_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getQuataBalance: async (claimId : any) => {
       
        try {
               const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/getRemainingBalance/'  + lionUserManager.user.data.USER_ID);
              if (res.status == 200) { 
                 
                dispatch({
                    type: ClaimActionTypes.GET_QUATA_BALANCE,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
           
            throw err;
        }
    },


    updateClaimStatus: async (data : any  ) => {
        try {
               
               const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/approve',  data);
               if (res.status == 200) {
                 
                return true;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                return false;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    

 

    getUserHistory: async () => {
        try {
            const form = new FormData();
            form.append("userId", lionUserManager.user.data.USER_ID);
            form.append("status", "-1");
            form.append("count", "30");
            const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/get', form); 
            if (res.status == 200) { 
                dispatch({
                    type: ClaimActionTypes.GET_USER_HISTORY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
 
    sendToHrApproval: async (claims_id : any) => {
        try { 
            const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/sendToHR/' + claims_id , {});
            if (res.status == 200) {
                 return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    deleteClaim: async (claims_id : any) => {
        try { 
            const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/delete/' + claims_id  );
 
            if (res.status == 201) {
                 return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getPendigPaymentList: async () => {
         
        try {
            const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/getHaveToSentEsb');  
            if (res.status == 200) {
                dispatch({
                    type: ClaimActionTypes.GET_PENDIGN_PAYMENT,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
	getUploadedDocuments: async () => {
		console.log("sasasasasa")
        try { 
            const res = await lionUserManager.authenticationManager.get('/clubMembership/document/getDoc');
           console.log(res)
            if (res.status == 200) {  
                
                    dispatch({
                        type: ClaimActionTypes.DOCUMENTS,
                        payload: res.data
                    });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }

	},
	getApprovedClaimDetailsReport :   async (data : any) => {
        try {
            
               const res = await lionUserManager.authenticationManager.post('/clubMembership/reimbursement/getapproves' , data);
               console.log("sasasa" ,res)
            if (res.status == 200) {
                
                    dispatch({
                         type: ClaimActionTypes.APPROVED_CLAIM_REPORT_DATA,
                          payload: res.data
                    });
                
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
  
    sendForPayment: async () => { 
        try {
            const res = await lionUserManager.authenticationManager.get('/clubMembership/reimbursement/approvedSendToEsb');  
            if (res.status == 201) {
                return true;
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },


 
})

export class ClaimActionTypes {
    static GET_ALL_TAG_CARD_DATA = 'GET_ALL_TAG_CARD_DATA';
    static GET_USER_HISTORY = 'GET_USER_HISTORY'; 
    static GET_ALL_CLAIMS = 'GET_ALL_CLAIMS';  
    static GET_All_Details_CLAIM_BY_ID = 'GET_All_Details_CLAIM_BY_ID'
    static GET_QUATA_BALANCE = 'GET_QUATA_BALANCE'
    static GET_USER_SUMMARY = 'GET_USER_SUMMARY'
	static GET_PENDIGN_PAYMENT = 'GET_PENDIGN_PAYMENT'
	static DOCUMENTS = "DOCUMENTS"
	static APPROVED_CLAIM_REPORT_DATA = 'APPROVED_CLAIM_REPORT_DATA'

}